import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { MenuItem, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import {
  Status,
  TimeOffListingDocument,
  TimeOffStatus,
  useTimeOffInsertMutation,
  useTimeOffListingQuery,
  useTimeOffReasonByTlaQuery,
  DocumentType,
  useGetSuperiorByEmployeeQuery,
  useGetSuperiorQuery,
  useApprovalPolicyAssignmentListQuery,
  useApprovalPolicyListQuery,
  useEmployeeListingByRoleIdLazyQuery,
  useGetEmployeeAppSecurityPermissionsByContactIdQuery,
  HrPermission,
  useNotificationUpdateMutation,
  useTimeOffPolicyDetailsByEmployeeIdLazyQuery,
  useGetDutyRosterByEmployeeLazyQuery,
  ApprovalStatus,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { InputUploadAllType } from 'components/Input/InputUploadAllType'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import { approvalWorkflowRoleID } from 'containers/helper/WorkFlowHelper'
import { Autocomplete } from '@mui/material'

interface TimeOffForm {
  TimeOffID?: string
  WorkDate?: Date
  StartTime?: Date
  EndTime?: Date
  ToReasonID?: string
  Remark?: string
  EmployeeID?: string
  TimeOffStatus?: string
  VisitingPlace: string
  TravelFrom: string
  TravelTo: string
  Duration: string
  LeaveType: LeaveType
}
interface LeaveType {
  LeaveTypeID: string
  Name: string
}
export const TimeOffForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  let formMode = 'New'

  const { state }: any = useLocation()
  const editData = location?.state as any
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const loggedInEmployee = JSON.parse(localStorage.getItem('loggedInEmployee'))
  const EmployeeDetails = JSON.parse(localStorage.getItem('Employee'))
  const [imagePreview, setImagePreview] = useState(
    state?.TimeOffListing?.Document?.DocumentFile || null
  )
  const [selectedFile, setSelectedFile] = useState({ files: [] })
  const [selectedFileName, setSelectedFileName] = useState(
    state?.TimeOffListing?.Document?.Description || ''
  )
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)

  const [disableConfirm, setDisableConfirm] = useState(false)

  const [ShowLeaveSelection, setShowLeaveSelection] = useState(false)
  const [
    LeaveDeductionDailyWorkingHour,
    setLeaveDeductionDailyWorkingHour,
  ] = useState(0)
  const [durationArray, setDurationArray] = useState([])
  const [leaveTypeArray, setLeaveTypeArray] = useState<LeaveType[]>([])

  const snackFunc = (showText: string, goBack: Boolean) => {
    setSnackBarMessage(showText)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
    }, 1000)
  }
  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    setError,
    getValues,
    clearErrors,
    watch,
  } = useForm<TimeOffForm>({
    defaultValues: {
      Remark: editData?.TimeOffListing?.Remark,
      WorkDate: editData?.TimeOffListing?.WorkDate || new Date(),
      ToReasonID: editData?.TimeOffListing?.ToReasonID,
      EmployeeID: editData?.TimeOffListing?.EmployeeID,
      VisitingPlace: editData?.TimeOffListing?.VistingPlace,
      TravelFrom: editData?.TimeOffListing?.TravelFrom,
      TravelTo: editData?.TimeOffListing?.TravelTo,
      // Duration: editData?.TimeOffListing?.Duration,
      // LeaveType: editData?.TimeOffListing?.LeaveTypeID,
    },
    mode: 'onBlur',
  })
  const {
    data: { TimeOffListing } = {
      TimeOffListing: [],
    },
  } = useTimeOffListingQuery({
    variables: {
      CompanyID: loggedInEmployee?.companyID,
      EmployeeID: loggedInEmployee.employeeID,
    },
    fetchPolicy: 'network-only',
  })
  const TimeOffListingByEmployee = TimeOffListing

  const {
    data: { TimeOffReasonByTLA } = {
      TimeOffReasonByTLA: [],
    },
  } = useTimeOffReasonByTlaQuery({
    variables: {
      CompanyID: loggedInEmployee?.companyID,
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: { GetEmployeeAppSecurityPermissionsByContactID } = {
      GetEmployeeAppSecurityPermissionsByContactID: [],
    },
    loading: GetEmployeeAppLoading,
  } = useGetEmployeeAppSecurityPermissionsByContactIdQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ContactID: JSON.parse(localStorage.getItem('Employee'))?.ContactID,
      Permissions: [
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead,

        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit,
      ],
    },
  })

  const {
    data: { ApprovalPolicyAssignmentList } = {
      ApprovalPolicyAssignmentList: null,
    },
    loading: ApprovalPolicyAssignmentLoading,
    refetch: ApprovalPolicyAssignmentRefetch,
  } = useApprovalPolicyAssignmentListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      SubscriptionAccountID: loggedInEmployee?.subscriptionAccountID,
      CompanyID: loggedInEmployee?.companyID,
      Category: 'TimeOff_TMS',
    },
  })

  const {
    data: { ApprovalPolicyList } = { ApprovalPolicyList: [] },
    loading: approvalLoading,
    refetch,
  } = useApprovalPolicyListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      SubscriptionAccountID: loggedInEmployee?.subscriptionAccountID,
      Category: 'TimeOff_TMS',
    },
  })

  useEffect(() => {
    if (ApprovalPolicyList && ApprovalPolicyAssignmentList) {
      getEmployeeListingByRoleId()
    }
  }, [ApprovalPolicyList.length && ApprovalPolicyAssignmentList])

  const [
    getEmployeeListingByRoleId,
    {
      loading: EmployeeListingByRoleIDLoading,
      data: { EmployeeListingByRoleID } = { EmployeeListingByRoleID: [] },
    },
  ] = useEmployeeListingByRoleIdLazyQuery({
    variables: {
      SubscriptionAccountID: loggedInEmployee?.subscriptionAccountID,
      CompanyID: loggedInEmployee?.companyID,
      RoleID: approvalWorkflowRoleID(
        ApprovalPolicyList,
        ApprovalPolicyAssignmentList,
        'TimeOff_TMS Submission',
        1
      ),
    },
    fetchPolicy: 'no-cache',
  })

  const [notificationUpdateMutation] = useNotificationUpdateMutation()

  const [selectedStartDate, handleStartDateChange] = useState(
    editData?.TimeOffListing?.StartTime || new Date()
  )
  const [selectedExpiryDate, handleExpiryDateChange] = useState(
    editData?.TimeOffListing?.EndTime || new Date()
  )
  useEffect(() => {
    if (editData?.TimeOffListing?.LeaveTypeID && leaveTypeArray.length > 0) {
      const defaultLeaveType = leaveTypeArray.find(
        type => type.LeaveTypeID === editData.TimeOffListing.LeaveTypeID
      )
      setValue('LeaveType', defaultLeaveType)
    }
    if (editData?.TimeOffListing?.Duration) {
      setValue(`Duration`, editData?.TimeOffListing?.Duration)
    }
  }, [editData, leaveTypeArray, setValue])
  const [TimeOffInsert, { loading: toLoading }] = useTimeOffInsertMutation({
    onError: error => {
      setError(`Remark`, {
        type: 'required',
        message: error.message.replace('GraphQL error:', ''),
      })
      setDisableConfirm(false)
    },
    onCompleted: data => {
      if (data.TimeOffInsert.TimeOffID !== '') {
        snackFunc('Insert Successfully!', false)
        setTimeout(() => {
          history.push(`/Employee/tms/timeofflist`)
        }, 500)
      } else {
        snackFunc('Insert Failed!', false)
        setDisableConfirm(false)
      }
    },
  })
  //
  const [
    callingDutyRoster,

    {
      loading: dutyRosterLoading,
      called: dutyRosterCalled,
      refetch: dutyRosterRefetch,
      data,
    },
  ] = useGetDutyRosterByEmployeeLazyQuery({
    fetchPolicy: 'network-only',
  })
  //get the leave and duration
  const [
    getTimeOffPolicyDetails,
    {
      data: { TimeOffPolicyDetailsByEmployeeID } = {
        TimeOffPolicyDetailsByEmployeeID: [],
      },
    },
  ] = useTimeOffPolicyDetailsByEmployeeIdLazyQuery({
    onCompleted: data => {
      // console.log('data time off', data?.TimeOffPolicyDetailsByEmployeeID)
      const leavetype = data?.TimeOffPolicyDetailsByEmployeeID[0]?.LeaveType
      const duration = data?.TimeOffPolicyDetailsByEmployeeID[0]?.Duration
      const IsLeaveDeduction =
        data?.TimeOffPolicyDetailsByEmployeeID[0]?.IsLeaveDeduction
      const DailyWorkingHour =
        data?.TimeOffPolicyDetailsByEmployeeID[0]?.DailyWorkingHours ?? 0
      // console.log('data duration', duration)
      // console.log('leavetype', leavetype)

      if (duration) {
        const durationArray = duration.split(',').map(item => item.trim())
        setDurationArray(durationArray)
        // console.log('duration split', durationArray)
      } else {
        // console.log('No duration found')
      }
      if (IsLeaveDeduction) {
        setShowLeaveSelection(true)
        setLeaveTypeArray(leavetype)
        setLeaveDeductionDailyWorkingHour(+DailyWorkingHour)
      }
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    // console.log("getValues('WorkDate')", getValues('WorkDate'))
    // console.log('loggedInEmployee.employeeID,', loggedInEmployee.employeeID)
    // console.log('loggedInEmployee?.companyID', loggedInEmployee?.companyID)

    let date = getValues('WorkDate')
    let EmployeeID = loggedInEmployee.employeeID
    let companyID = loggedInEmployee?.companyID
    if (loggedInEmployee?.companyID && loggedInEmployee.employeeID) {
      getTimeOffPolicyDetails({
        variables: {
          EmployeeID: EmployeeID,
          CompanyID: companyID,
          Date: date,
        },
      })
    }
    callingDutyRoster({
      variables: {
        FromDate: date.toString(),
        ToDate: date.toString(),
        EmployeeID: EmployeeID,
      },
    })
  }, [getValues('WorkDate')])

  //For user select Duration to calculate the EndTime and make the starttime without second
  useEffect(() => {
    const duration = watch(`Duration`)

    function extractTime(date) {
      const d = new Date(date)
      const hours = d
        .getHours()
        .toString()
        .padStart(2, '0')
      const minutes = d
        .getMinutes()
        .toString()
        .padStart(2, '0')
      return `${hours}:${minutes}`
    }

    const calculateEndTime = duration => {
      let endTime
      const startDate = new Date(selectedStartDate)
      startDate.setSeconds(0, 0) // Ensure startDate is a Date object
      if (duration) {
        const durationInMinutes = parseInt(duration, 10)
        endTime = new Date(startDate.getTime() + durationInMinutes * 60000)
        endTime.setSeconds(0, 0)

        if (data?.getDutyRosterByEmployee.length > 0) {
          const [dutyroster] = data?.getDutyRosterByEmployee || []
          const shiftDay = dutyroster?.shiftDay
          const breakTimeList = shiftDay?.breakTimeList || []

          if (shiftDay && breakTimeList.length > 0) {
            const {
              Start: breakTimeStart,
              End: breakTimeEnd,
            } = breakTimeList[0]

            const shiftStartTime = shiftDay?.ShiftStartTime
            const shiftEndTime = shiftDay?.ShiftEndTime
            // Convert strings to Date objects
            const breakTimeStart1 = new Date(breakTimeStart)
            const breakTimeEnd1 = new Date(breakTimeEnd)
            const shiftStartTime1 = new Date(shiftStartTime)
            const shiftEndTime1 = new Date(shiftEndTime)

            // Check if the shift Date objects are valid
            if (
              isNaN(shiftStartTime1.getTime()) ||
              isNaN(shiftEndTime1.getTime())
            ) {
              console.error(
                'Invalid shift time date format:',
                shiftStartTime,
                shiftEndTime
              )

              return // Exit the function if dates are invalid
            }

            // Extract time parts (hours and minutes)
            const startTimeHours = startDate.getHours()
            const startTimeMinutes = startDate.getMinutes()

            const shiftStartHours = shiftStartTime1.getHours()
            const shiftStartMinutes = shiftStartTime1.getMinutes()

            const shiftEndHours = shiftEndTime1.getHours()
            const shiftEndMinutes = shiftEndTime1.getMinutes()

            // Function to convert hours and minutes to a comparable value (minutes since midnight)
            const toMinutes = (hours, minutes) => hours * 60 + minutes

            const startTimeInMinutes = toMinutes(
              startTimeHours,
              startTimeMinutes
            )
            const shiftStartInMinutes = toMinutes(
              shiftStartHours,
              shiftStartMinutes
            )
            const shiftEndInMinutes = toMinutes(shiftEndHours, shiftEndMinutes)

            // Check if startDate is within the shift time range
            if (
              startTimeInMinutes < shiftStartInMinutes ||
              startTimeInMinutes > shiftEndInMinutes
            ) {
              // console.log('startDate is outside the shift time range.')
              setError('StartTime', {
                type: 'manual',
                message: 'Start time is outside the shift time range.',
              })
              return // Exit the function if startDate is outside shift time range
            } else {
              clearErrors('StartTime')
            }

            // Now check if startDate is before or within the break time range
            const breakStartHours = breakTimeStart1.getHours()
            const breakStartMinutes = breakTimeStart1.getMinutes()

            const breakEndHours = breakTimeEnd1.getHours()
            const breakEndMinutes = breakTimeEnd1.getMinutes()

            const breakStartInMinutes = toMinutes(
              breakStartHours,
              breakStartMinutes
            )
            const breakEndInMinutes = toMinutes(breakEndHours, breakEndMinutes)

            if (startTimeInMinutes <= breakEndInMinutes) {
              // console.log('startDate is before or within the break time range.')

              if (
                startTimeInMinutes >= breakStartInMinutes &&
                startTimeInMinutes <= breakEndInMinutes
              ) {
                // console.log('input starttime in the break time range')
                const newEndTimeInMinutes =
                  breakEndInMinutes + durationInMinutes
                const newEndTimeHours = Math.floor(newEndTimeInMinutes / 60)
                const newEndTimeMinutes = newEndTimeInMinutes % 60
                endTime.setHours(newEndTimeHours, newEndTimeMinutes)
              } else {
                // console.log('input starttime is not in the break time range')
                // Calculate the break duration in milliseconds
                let breakDuration =
                  breakTimeEnd1.getTime() - breakTimeStart1.getTime()
                // console.log('Break duration in milliseconds:', breakDuration)

                // Adjust endTime if necessary based on breakDuration
                if (breakDuration > 0) {
                  endTime = new Date(endTime.getTime() + breakDuration)
                }
              }
            } else {
              // console.log('startDate is after the break time range.')
            }
          }
        }
      } else {
        endTime = new Date(startDate)
        endTime.setSeconds(0, 0)
      }

      handleExpiryDateChange(endTime)
    }

    calculateEndTime(duration)
  }, [watch(`Duration`), selectedStartDate, data?.getDutyRosterByEmployee])

  const [TOReason, setTOReason] = useState(editData?.editData?.ToReasonID || '')

  const onChangeFile = event => {
    let nextState = { ...selectedFile }
    nextState.files.splice(0, nextState.files.length)
    nextState.files.push(event.target.files[0])
    setSelectedFileName(event.target.files[0]?.name)
    setSelectedFile(nextState)
    let reader = new FileReader()

    reader.onloadend = () => {
      setImagePreview(reader.result)
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const refQuery = data => {
    return [
      {
        query: TimeOffListingDocument,
        variables: {
          CompanyID: loggedInEmployee?.companyID,
        },
      },
    ]
  }
  const onDraft = (data: TimeOffForm) => {
    setDisableConfirm(true)
    let Document: any = null
    let DocumentID: any = null
    const commonInput = {
      CreatedDT: new Date(),
      CreatedBy: loggedInEmployee?.employeeID,
      ModifiedDT: new Date(),
      ModifiedBy: loggedInEmployee?.employeeID,
    }
    if (selectedFile.files.length > 0) {
      Document = {
        DocumentType: DocumentType.Tms,
        Attachment: selectedFile.files[0],
        Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
        FileSize: selectedFile.files[0].size,
        Status: Status.Active,
        ...commonInput,
      }
    } else {
      DocumentID = state?.TimeOffListing?.Document?.DocumentID
    }

    if (TimeOffReasonByTLA.length > 0) {
      if (data?.ToReasonID) {
        setTOReason(data?.ToReasonID)
        const commonCreatedData = {
          CreatedDT: new Date(),
          CreatedBy: loggedInEmployee?.employeeID,
          ModifiedDT: new Date(),
          ModifiedBy: loggedInEmployee?.employeeID,
          Status: Status.Active,
        }
        const commonUpdatedData = {
          CreatedDT: editData?.TimeOffListing?.CreatedDT,
          CreatedBy: editData?.TimeOffListing?.CreatedBy,
          ModifiedDT: new Date(),
          ModifiedBy: loggedInEmployee?.employeeID,
        }
        if (
          TimeOffListingByEmployee &&
          TimeOffListingByEmployee.filter(
            x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
          ).length > 0
        ) {
          // console.log('Draft 1')
          // exist Draft submit update the draft again
          let ssd=selectedStartDate?.setDate(data?.WorkDate?.getDate())
          let sed=selectedExpiryDate?.setDate(data?.WorkDate?.getDate())


          TimeOffInsert({
            variables: {
              input: {
                ...commonUpdatedData,
                ApproverID: EmployeeDetails?.ReportTo,
                TimeOffID: editData?.TimeOffListing?.TimeOffID,
                EmployeeID: loggedInEmployee.employeeID,
                Document: Document,
                DocumentID: DocumentID,
                Remark: data?.Remark,
                WorkDate: data?.WorkDate,
                StartTime: new Date(ssd),
                EndTime: new Date(sed) ,
                ToReasonID: data?.ToReasonID,
                TimeOffStatus: TimeOffStatus.Draft,
                VisitingPlace: data?.VisitingPlace,
                TravelFrom: data?.TravelFrom,
                TravelTo: data?.TravelTo,
                CompanyID: loggedInEmployee?.companyID,
                Duration: Number(data?.Duration),
                LeaveTypeID: data?.LeaveType?.LeaveTypeID,
                LeaveApprovalStatus: ApprovalStatus.Draft,
                IsLeaveDeduction: ShowLeaveSelection,
                DailyWorkingHours: LeaveDeductionDailyWorkingHour,
                // Duration: timeElapsedByMin(
                //   new Date(selectedExpiryDate),
                //   new Date(selectedStartDate)
                // ),
              },
            },
          })
        } else {
          // console.log('Draft 2')
          // first user input save as draft
          // return
          let ssd=selectedStartDate?.setDate(data?.WorkDate?.getDate())
          let sed=selectedExpiryDate?.setDate(data?.WorkDate?.getDate())


          TimeOffInsert({
            variables: {
              input: {
                ...commonCreatedData,
                ApproverID: EmployeeDetails?.ReportTo,
                EmployeeID: loggedInEmployee.employeeID,
                ToReasonID: data?.ToReasonID,
                WorkDate: data?.WorkDate,
                StartTime: new Date(ssd),
                EndTime: new Date(sed) ,
                Document: Document,
                DocumentID: DocumentID,
                Remark: data?.Remark,
                TimeOffStatus: TimeOffStatus.Draft,
                VisitingPlace: data?.VisitingPlace,
                TravelFrom: data?.TravelFrom,
                TravelTo: data?.TravelTo,
                CompanyID: loggedInEmployee?.companyID,
                Duration: Number(data?.Duration),
                LeaveTypeID: data?.LeaveType?.LeaveTypeID,
                LeaveApprovalStatus: ApprovalStatus.Draft,
                IsLeaveDeduction: ShowLeaveSelection,
                DailyWorkingHours: LeaveDeductionDailyWorkingHour,
                // Duration: timeElapsedByMin(
                //   new Date(selectedExpiryDate),
                //   new Date(selectedStartDate)
                // ),
              },
            },
          })
        }
      } else {
        setError(`ToReasonID`, {
          type: 'required',
          message: 'Reason is required',
        })
      }
    } else {
      const commonCreatedData = {
        CreatedDT: new Date(),
        CreatedBy: loggedInEmployee?.employeeID,
        ModifiedDT: new Date(),
        ModifiedBy: loggedInEmployee?.employeeID,
        Status: Status.Active,
      }
      const commonUpdatedData = {
        ModifiedDT: new Date(),
        ModifiedBy: loggedInEmployee?.employeeID,
      }
      if (
        TimeOffListingByEmployee &&
        TimeOffListingByEmployee.filter(
          x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
        ).length > 0
      ) {
        // console.log('Draft 3')
        // return

        let ssd=selectedStartDate?.setDate(data?.WorkDate?.getDate())
        let sed=selectedExpiryDate?.setDate(data?.WorkDate?.getDate())

        TimeOffInsert({
          variables: {
            input: {
              ...commonUpdatedData,
              ApproverID: EmployeeDetails?.ReportTo,
              TimeOffID: editData?.TimeOffListing?.TimeOffID,
              EmployeeID: loggedInEmployee.employeeID,
              Remark: data?.Remark,
              WorkDate: data?.WorkDate,
              Document: Document,
              DocumentID: DocumentID,
              StartTime: new Date(ssd),
              EndTime: new Date(sed) ,
              ToReasonID: data?.ToReasonID,
              TimeOffStatus: TimeOffStatus.Draft,
              VisitingPlace: data?.VisitingPlace,
              TravelFrom: data?.TravelFrom,
              TravelTo: data?.TravelTo,
              CompanyID: loggedInEmployee?.companyID,
              Duration: Number(data?.Duration),
              LeaveTypeID: data?.LeaveType?.LeaveTypeID,
              LeaveApprovalStatus: ApprovalStatus.Draft,
              IsLeaveDeduction: ShowLeaveSelection,
              DailyWorkingHours: LeaveDeductionDailyWorkingHour,
              // Duration: timeElapsedByMin(
              //   new Date(selectedExpiryDate),
              //   new Date(selectedStartDate)
              // ),
            },
          },
        })
      } else {
        // console.log('Draft 4')
        // return

        let ssd=selectedStartDate?.setDate(data?.WorkDate?.getDate())
        let sed=selectedExpiryDate?.setDate(data?.WorkDate?.getDate())

        TimeOffInsert({
          variables: {
            input: {
              ...commonCreatedData,
              ApproverID: EmployeeDetails?.ReportTo,
              EmployeeID: loggedInEmployee.employeeID,
              ToReasonID: data?.ToReasonID,
              WorkDate: data?.WorkDate,
              StartTime: new Date(ssd),
              EndTime: new Date(sed) ,
              Document: Document,
              DocumentID: DocumentID,
              Remark: data?.Remark,
              TimeOffStatus: TimeOffStatus.Draft,
              VisitingPlace: data?.VisitingPlace,
              TravelFrom: data?.TravelFrom,
              TravelTo: data?.TravelTo,
              CompanyID: loggedInEmployee?.companyID,
              Duration: Number(data?.Duration),
              LeaveTypeID: data?.LeaveType?.LeaveTypeID,
              LeaveApprovalStatus: ApprovalStatus.Draft,
              IsLeaveDeduction: ShowLeaveSelection,
              DailyWorkingHours: LeaveDeductionDailyWorkingHour,
              // Duration: timeElapsedByMin(
              //   new Date(selectedExpiryDate),
              //   new Date(selectedStartDate)
              // ),
            },
          },
        })
      }
    }
  }

  if (
    TimeOffListingByEmployee &&
    TimeOffListingByEmployee.filter(
      x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
    ).length > 0
  ) {
    formMode = 'Edit'
  }

  let toList = TimeOffListingByEmployee.filter(
    x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
  )

  useEffect(() => {
    if (formMode == 'Edit') {
      setValue('EmployeeID', toList[0]?.EmployeeID)
      setValue('TimeOffID', toList[0]?.TimeOffID)
      setValue('Remark', toList[0]?.Remark)
      setValue('WorkDate', toList[0]?.WorkDate)
      setValue('StartTime', toList[0]?.StartTime)
      setValue('EndTime', toList[0]?.EndTime)
      setValue('ToReasonID', toList[0]?.ToReasonID)
      setValue('TimeOffStatus', toList[0]?.TimeOffStatus)
      setValue('VisitingPlace', toList[0]?.VisitingPlace)
      setValue('TravelFrom', toList[0]?.TravelFrom)
      setValue('TravelTo', toList[0]?.TravelTo)
    }
  }, [formMode])

  const onSubmit = (data: TimeOffForm) => {
    console.log('data submit', data)
    // console.log('data Duration', data?.Duration)
    // return
    let Document: any = null
    let DocumentID: any = null
    setDisableConfirm(true)
    const commonInput = {
      CreatedDT: new Date(),
      CreatedBy: loggedInEmployee?.employeeID,
      ModifiedDT: new Date(),
      ModifiedBy: loggedInEmployee?.employeeID,
    }
    if (selectedFile.files.length > 0) {
      Document = {
        DocumentType: DocumentType.Tms,
        Attachment: selectedFile.files[0],
        Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
        FileSize: selectedFile.files[0].size,
        Status: Status.Active,
        ...commonInput,
      }
    } else {
      DocumentID = state?.TimeOffListing?.Document?.DocumentID
    }
    if (TimeOffReasonByTLA.length > 0) {
      if (data?.ToReasonID) {
        const commonCreatedData = {
          CreatedDT: new Date(),
          CreatedBy: loggedInEmployee?.employeeID,
          ModifiedDT: new Date(),
          ModifiedBy: loggedInEmployee?.employeeID,
          Status: Status.Active,
        }
        const commonUpdatedData = {
          CreatedDT: editData?.TimeOffListing?.CreatedDT,
          CreatedBy: editData?.TimeOffListing?.CreatedBy,
          ModifiedDT: new Date(),
          ModifiedBy: loggedInEmployee?.employeeID,
        }

        if (
          TimeOffListingByEmployee &&
          TimeOffListingByEmployee.filter(
            x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
          ).length > 0
        ) {
          // console.log('Submit 1')
          // return
       
          let ssd=selectedStartDate?.setDate(data?.WorkDate?.getDate())
          let sed=selectedExpiryDate?.setDate(data?.WorkDate?.getDate())

        

          TimeOffInsert({
            variables: {
              input: {
                ...commonUpdatedData,
                ApproverID: EmployeeDetails?.ReportTo,
                TimeOffID: editData?.TimeOffListing?.TimeOffID,
                EmployeeID: loggedInEmployee?.employeeID,
                Remark: data?.Remark,
                WorkDate: data?.WorkDate,
                Document: Document,
                DocumentID: DocumentID,
                StartTime: new Date(ssd),
                EndTime: new Date(sed) ,
                ToReasonID: data?.ToReasonID,
                TimeOffStatus: TimeOffStatus.Pending,
                VisitingPlace: data?.VisitingPlace,
                TravelFrom: data?.TravelFrom,
                TravelTo: data?.TravelTo,
                CompanyID: loggedInEmployee?.companyID,
                Duration: Number(data?.Duration),
                LeaveTypeID: data?.LeaveType?.LeaveTypeID,
                LeaveApprovalStatus: ApprovalStatus.Approved,
                IsLeaveDeduction: ShowLeaveSelection,
                DailyWorkingHours: LeaveDeductionDailyWorkingHour,
                // Duration: timeElapsedByMin(
                //   new Date(selectedExpiryDate),
                //   new Date(selectedStartDate)
                // ),
              },
            },
            refetchQueries: refQuery(data),
          })
        } else {
          // console.log('Submit 2')
          //create new add
          let ssd=selectedStartDate?.setDate(data?.WorkDate?.getDate())
          let sed=selectedExpiryDate?.setDate(data?.WorkDate?.getDate())


          TimeOffInsert({
            variables: {
              input: {
                ...commonCreatedData,
                ApproverID: EmployeeDetails?.ReportTo,
                EmployeeID: loggedInEmployee.employeeID,
                ToReasonID: data?.ToReasonID,
                WorkDate: data?.WorkDate,
                StartTime: new Date(ssd),
                EndTime: new Date(sed) ,
                Remark: data?.Remark,
                Document: Document,
                DocumentID: DocumentID,
                TimeOffStatus: TimeOffStatus.Pending,
                VisitingPlace: data?.VisitingPlace,
                TravelFrom: data?.TravelFrom,
                TravelTo: data?.TravelTo,
                CompanyID: loggedInEmployee?.companyID,
                Duration: Number(data?.Duration),
                LeaveTypeID: data?.LeaveType?.LeaveTypeID,
                LeaveApprovalStatus: ApprovalStatus.Approved,
                IsLeaveDeduction: ShowLeaveSelection,
                DailyWorkingHours: LeaveDeductionDailyWorkingHour,
                // Duration: timeElapsedByMin(
                //   new Date(selectedExpiryDate),
                //   new Date(selectedStartDate)
                // ),
              },
            },
            refetchQueries: refQuery(data),
          })
        }
      } else {
        setError(`ToReasonID`, {
          type: 'required',
          message: 'Reason is required',
        })
      }
    } else {
      const commonCreatedData = {
        CreatedDT: new Date(),
        CreatedBy: loggedInEmployee?.employeeID,
        ModifiedDT: new Date(),
        ModifiedBy: loggedInEmployee?.employeeID,
        Status: Status.Active,
      }
      const commonUpdatedData = {
        CreatedDT: editData?.TimeOffListing?.CreatedDT,
        CreatedBy: editData?.TimeOffListing?.CreatedBy,
        ModifiedDT: new Date(),
        ModifiedBy: loggedInEmployee?.employeeID,
      }

      if (
        TimeOffListingByEmployee &&
        TimeOffListingByEmployee.filter(
          x => x.TimeOffID == editData?.TimeOffListing?.TimeOffID
        ).length > 0
      ) {
        // console.log('Submit 3')
        // return
        let ssd=selectedStartDate?.setDate(data?.WorkDate?.getDate())
        let sed=selectedExpiryDate?.setDate(data?.WorkDate?.getDate())


        TimeOffInsert({
          variables: {
            input: {
              ...commonUpdatedData,
              ApproverID: EmployeeDetails?.ReportTo,
              TimeOffID: editData?.TimeOffListing?.TimeOffID,
              EmployeeID: loggedInEmployee?.employeeID,
              Remark: data?.Remark,
              WorkDate: data?.WorkDate,
              StartTime: new Date(ssd),
              EndTime: new Date(sed) ,
              Document: Document,
              DocumentID: DocumentID,
              ToReasonID: data?.ToReasonID,
              TimeOffStatus: TimeOffStatus.Pending,
              VisitingPlace: data?.VisitingPlace,
              TravelFrom: data?.TravelFrom,
              TravelTo: data?.TravelTo,
              CompanyID: loggedInEmployee?.companyID,
              Duration: Number(data?.Duration),
              LeaveTypeID: data?.LeaveType?.LeaveTypeID,
              LeaveApprovalStatus: ApprovalStatus.Approved,
              IsLeaveDeduction: ShowLeaveSelection,
              DailyWorkingHours: LeaveDeductionDailyWorkingHour,
              // Duration: timeElapsedByMin(
              //   new Date(selectedExpiryDate),
              //   new Date(selectedStartDate)
              // ),
            },
          },
          refetchQueries: refQuery(data),
        })
      } else {
        // console.log('Submit 4')
        // return
        let ssd=selectedStartDate?.setDate(data?.WorkDate?.getDate())
        let sed=selectedExpiryDate?.setDate(data?.WorkDate?.getDate())


        TimeOffInsert({
          variables: {
            input: {
              ...commonCreatedData,
              ApproverID: EmployeeDetails?.ReportTo,
              EmployeeID: loggedInEmployee.employeeID,
              ToReasonID: data?.ToReasonID,
              WorkDate: data?.WorkDate,
              StartTime: new Date(ssd),
              EndTime: new Date(sed) ,
              Remark: data?.Remark,
              Document: Document,
              DocumentID: DocumentID,
              TimeOffStatus: TimeOffStatus.Pending,
              VisitingPlace: data?.VisitingPlace,
              TravelFrom: data?.TravelFrom,
              TravelTo: data?.TravelTo,
              CompanyID: loggedInEmployee?.companyID,
              Duration: Number(data?.Duration),
              LeaveTypeID: data?.LeaveType?.LeaveTypeID,
              LeaveApprovalStatus: ApprovalStatus.Approved,
              IsLeaveDeduction: ShowLeaveSelection,
              DailyWorkingHours: LeaveDeductionDailyWorkingHour,
              // Duration: timeElapsedByMin(
              //   new Date(selectedExpiryDate),
              //   new Date(selectedStartDate)
              // ),
            },
          },
          refetchQueries: refQuery(data),
        })
      }
    }
  }

  return (
    <>
      {toLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        smTitle={'Employee Super App'}
        title={
          EmployeeDetails?.Salutation != ''
            ? `${EmployeeDetails?.Salutation} ${EmployeeDetails?.Contact?.FullName}`
            : `${EmployeeDetails?.Contact?.FullName}`
        }
        onClick={() =>
          history.push('/Employee/tms/timeofflist', {
            filterSearch: state?.filterSearch,
          })
        }
        routeSegments={[
          { name: 'My Time Off' },
          { name: 'My Time Off' },
          { name: 'My Time Off' },
          { name: 'My Time Off', current: true },
        ]}
        rightRouteSegments={[
          { name: `${formMode == 'Edit' ? 'Edit' : 'New'}`, current: true },
        ]}
      />

      <form id="submit-form">
        <ContentWrapper footer>
          <CardContents>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="WorkDate"
                label="Work Date"
                required
                autoOk={true}
                control={control}
                onChange={date => {
                  setValue('WorkDate', date)
                  return { value: date } //important to update the controller value after change else state is updated and the controller will not render
                }}
                defaultValue={null}
                format="dd MMM yyyy"
                value={watch('WorkDate')}
                allowKeyboardControl
                margin="normal"
                inputProps={{ readOnly: true }}
                ref={register}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                error={errors?.WorkDate ? true : false}
                helperText={errors?.WorkDate?.message}
              />
            </MuiPickersUtilsProvider>

            {ShowLeaveSelection && (
              <>
                <Controller
                  name="LeaveType"
                  control={control}
                  fullWidth
                  render={({ onChange, value }) => (
                    <Autocomplete
                      fullWidth
                      {...props}
                      options={leaveTypeArray}
                      value={value || null}
                      noOptionsText="No Leave Found"
                      getOptionLabel={(option: LeaveType) => `${option?.Name}`}
                      onChange={(e, value: any) => {
                        onChange(value)
                        // console.log('value leave', value)
                      }}
                      renderInput={params => (
                        <TextField
                          required
                          ref={register}
                          label="Leave Type"
                          {...params}
                          placeholder="Leave Type..."
                          margin="none"
                          variant="standard"
                          value={watch('LeaveType')}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                required
                value={selectedStartDate}
                margin="normal"
                name="StartTime"
                label="Start Time"
                onChange={date => {
                  handleStartDateChange(date)
                  if (formMode === 'New') {
                    handleExpiryDateChange(date)
                  }
                }}
                format="hh:mm a"
                fullWidth
                id="time-picker"
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                inputProps={{ readOnly: true }}
                className="left"
                error={!!errors.StartTime}
                helperText={errors.StartTime?.message}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                value={selectedExpiryDate}
                margin="normal"
                name="EndTime"
                label="End Time"
                disabled
                onChange={date => {
                  handleExpiryDateChange(date)
                }}
                format="hh:mm a"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                id="time-picker"
                inputProps={{ readOnly: true }}
                className="right"
              />
            </MuiPickersUtilsProvider>
            {/* Duration<br></br>
            {`${timeElapsed(selectedStartDate, selectedExpiryDate)} `} */}
            <Controller
              name="Duration"
              control={control}
              fullWidth
              render={({ onChange, value, name }) => (
                <Autocomplete
                  {...props}
                  style={{ width: 200 }}
                  options={durationArray}
                  value={value || null}
                  noOptionsText="No Duration Found"
                  onChange={(e, value: any) => {
                    onChange(value)
                    // calculateEndTime(value)
                  }}
                  renderInput={params => (
                    <TextField
                      required
                      // disabled={state?.EditData}
                      ref={register}
                      label="Duration(Minutes)"
                      {...params}
                      margin="none"
                      variant="standard"
                      value={watch('Duration')}
                    />
                  )}
                />
              )}
            />
            <br></br>
            <Controller
              as={TextField}
              id="standard-basic"
              defaultValue=""
              name="VisitingPlace"
              label="Visiting Place"
              fullWidth
              control={control}
              error={errors?.VisitingPlace ? true : false}
              helperText={errors?.VisitingPlace?.message}
            />
            <Controller
              as={TextField}
              id="standard-basic"
              defaultValue=""
              name="TravelFrom"
              label="Travel From"
              className="left"
              fullWidth
              control={control}
              error={errors?.TravelFrom ? true : false}
              helperText={errors?.TravelFrom?.message}
            />
            <Controller
              as={TextField}
              id="standard-basic"
              defaultValue=""
              name="TravelTo"
              label="Travel To"
              className="right"
              fullWidth
              control={control}
              error={errors?.TravelTo ? true : false}
              helperText={errors?.TravelTo?.message}
            />
            <Controller
              as={
                <TextField select>
                  {TimeOffReasonByTLA?.map((option, index) => (
                    <MenuItem
                      key={option?.TimeOffReasonID}
                      value={option?.TimeOffReasonID}
                    >
                      {option?.Name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              style={{ marginTop: '12px' }}
              className="m-0"
              id="standard-basic"
              label="Time Off Reason"
              fullWidth
              required
              name="ToReasonID"
              defaultValue={TOReason}
              control={control}
              error={errors?.ToReasonID}
              helperText={errors?.ToReasonID ? 'Reason is required!' : null}
            />
            <Controller
              as={TextField}
              className="m-0"
              fullWidth
              defaultValue=""
              name="Remark"
              label="Remark"
              control={control}
            />
            {true ? (
              <>
                <span className="color-red">
                  {errors?.Remark && errors?.Remark?.message}
                </span>
              </>
            ) : null}
            <InputUploadAllType
              value={selectedFileName}
              label="Attachment"
              handleUpload={e => onChangeFile(e)}
              className="p-b-20"
            />
            {imagePreview !== null ? (
              <UploadPreview
                src={imagePreview}
                onClick={() => {
                  setSelectedFile({ files: [] })
                  setSelectedFileName('')
                  setImagePreview(null)
                }}
                remove
              />
            ) : null}
          </CardContents>
        </ContentWrapper>
      </form>

      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />

      {user?.superUser ||
      GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit
      ) ? (
        <Footer
          options={[
            {
              name: 'Save as draft',
              onClick: handleSubmit(onDraft),
              color: 'primary',
              mode: 'edit',
              disabled: disableConfirm,
            },
            {
              name: 'Submit',
              onClick: handleSubmit(onSubmit),
              color: 'primary',
              disabled: disableConfirm,
            },
          ]}
        />
      ) : (
        <Footer
          options={[
            {
              name: 'Save as draft',
              onClick: handleSubmit(onDraft),
              color: 'primary',
              mode: 'edit',
              disabled: disableConfirm,
            },
          ]}
        />
      )}
    </>
  )
}
