import { ApprovalStatus } from 'generated/graphql'

// export const WorkflowHelper = (workflowStep: any, approverID?: string) => {
//   let StepApproved = 0
//   let CurrentApproverStepApproved = false
//   let IsCurrentApproveApproved = false
//   if (!workflowStep) {
//     return {
//       StepApproved,
//       CurrentApproverStepApproved,
//       IsCurrentApproveApproved,
//     }
//   }
//   for (const key of Object.keys(workflowStep)) {
//     let workflowApproved = workflowStep[key].some(
//       (x: any) => x?.ApprovalStatus == ApprovalStatus.Approved
//     )
//     if (workflowApproved) {
//       StepApproved++
//     }
//     if (approverID) {
//       let IsApproverStep = workflowStep[key].some(
//         (x: any) => x?.ApproverID == approverID
//       )
//       if (workflowApproved && IsApproverStep) {
//         CurrentApproverStepApproved = true
//       }

//       let IsApproverApproved = workflowStep[key].find(
//         (x: any) =>
//           x?.ApprovalStatus == ApprovalStatus.Approved &&
//           x?.ApproverID == approverID
//       )
//       if (IsApproverApproved) {
//         IsCurrentApproveApproved = true
//       }
//     }
//   }
//   return {
//     StepApproved,
//     CurrentApproverStepApproved,
//     IsCurrentApproveApproved,
//   }
// }

// export const WorkflowHelper = (
//   workflowStep: Array<any> | null,
//   approverID?: string
// ) => {
//   let StepApproved = 0
//   let CurrentApproverStepApproved = false
//   let IsCurrentApproveApproved = false

//   if (!workflowStep || workflowStep?.length == 0) {
//     return {
//       StepApproved,
//       CurrentApproverStepApproved,
//       IsCurrentApproveApproved,
//     }
//   }
//   for (const ws of workflowStep) {
//     if (
//       ws?.ApprovalStatus == ApprovalStatus.Approved ||
//       ws?.ApprovalStatus == ApprovalStatus.Withdraw
//     ) {
//       StepApproved++
//     }
//     // CurrentApproverStepApproved = false
//     for (const wsapproved of ws?.WorkFlowApprovedStep) {
//       if (
//         (wsapproved?.ApprovalStatus == ApprovalStatus.Approved ||
//           wsapproved?.ApprovalStatus == ApprovalStatus.Withdraw) &&
//         wsapproved?.ApproverID == approverID
//       ) {
//         IsCurrentApproveApproved = true
//         CurrentApproverStepApproved = true
//       }
//     }
//   }

//   return {
//     StepApproved,
//     CurrentApproverStepApproved,
//     IsCurrentApproveApproved,
//   }
// }

export const WorkflowHelper = (
  workflowStep: Array<any> | null,
  approverID?: string,
  roleIDs?: Array<string>
) => {
  let StepApproved = 0
  let CurrentApproverStepApproved = false
  let IsCurrentApproveApproved = false

  // console.log('workflowStepworkflowStep', workflowStep)

  if (!workflowStep || workflowStep?.length == 0) {
    return {
      StepApproved,
      CurrentApproverStepApproved,
      IsCurrentApproveApproved,
    }
  }
  let arr = false
  if (roleIDs?.length > 1) {
    let result = workflowStep?.filter(item =>
      roleIDs.includes(item?.ApproverRoleID)
    )
    arr = result?.some(x => x.ApprovalStatus === ApprovalStatus.Pending)
  }
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  for (const ws of workflowStep) {
    if (
      ws?.ApprovalStatus == ApprovalStatus.Approved ||
      ws?.ApprovalStatus == ApprovalStatus.Withdraw
    ) {
      StepApproved++
    }
    if (user?.superUser) {
      IsCurrentApproveApproved = true
      CurrentApproverStepApproved = true
    } else {
      for (const wsapproved of ws?.WorkFlowApprovedStep) {
        if (
          (wsapproved?.ApprovalStatus == ApprovalStatus.Approved ||
            wsapproved?.ApprovalStatus == ApprovalStatus.Withdraw) &&
          wsapproved?.ApproverID == approverID &&
          !arr
        ) {
          IsCurrentApproveApproved = true
          CurrentApproverStepApproved = true
        }
      }
    }
  }

  // console.log('StepApprovedStepApproved', StepApproved)
  // console.log('CurrentApproverStepApprovedCurrentApproverStepApproved', CurrentApproverStepApproved)
  // console.log('IsCurrentApproveApprovedIsCurrentApproveApproved', IsCurrentApproveApproved)

  return {
    StepApproved,
    CurrentApproverStepApproved,
    IsCurrentApproveApproved,
  }
}

// need to detect which role based on the level

export const approvalWorkflowRoleID = (
  ApprovalPolicyList,
  ApprovalPolicyAssignmentList,
  Category,
  StepNo,
) => {
  // console.log('CategoryCategory', Category)
  
  let approvalWorkflow = ApprovalPolicyList?.filter(
    x => x?.ID == ApprovalPolicyAssignmentList?.ApprovalPolicyID
  )?.map(x => x?.ApprovalList?.filter(
    x => x?.Category == Category && x?.StepNo == StepNo))
  
  // console.log('approvalWorkflowapprovalWorkflow', approvalWorkflow)

  return approvalWorkflow[0]?.[0]?.RoleID
}

